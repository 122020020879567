<template lang="html">
    <menu-content :active="menuShowing" @close="toggleMenu(false)" :user="user"></menu-content>
    <div id="topMenu" class="col-12 d-md-none d-flex justify-content-between alig-items-center align-content-center" :class="menuShowing ? 'hide' : 'show'">
        <div class="h-100 col-2 pb-1 pt-1 ps-4 d-flex justify-content-start align-items-end" @click="goTo('login')"><!--goToHome()-->
            <img :src="a6" class="h-75 object-fit-contain">
        </div>
        <div class="h-100 col-10 py-md-4 ps-2 text-end py-2 pe-4 d-flex justify-content-end align-items-center align-content-center" @click="toggleMenu(true)">
            <img :src="a12" class="h-50 object-fit-contain">
        </div>
    </div>
    <div id="topMenu" class="col-12 d-md-flex d-none justify-content-between alig-items-center align-content-center" :class="menuShowing ? 'hide' : 'show'">
        <div class="h-100 py-3 col-2 ps-5 d-flex justify-content-start align-items-center" @click="goTo('login')"><!--goToHome()-->
            <img :src="a6" class="h-75 object-fit-contain ps-3">
        </div>
        <div class="h-100 font-2 col-10 ps-2 pe-5 ts-22 fw-bold text-center pt-2 d-flex justify-content-end align-items-center align-content-center">
            <!-- <div class="col-auto menuLink me-3 me-md-4 me-lg-5" :class="this.$route.name == 'weare' ? 'active' : ''" role="button" @click="goTo('weare')">NOSOTROS</div>
            <div class="col-auto menuLink me-3 me-md-4 me-lg-5" :class="this.$route.name == 'trademarks' ? 'active' : ''" @click="goTo('trademarks')" role="button">MARCAS</div>
            <div class="col-auto menuLink me-3 me-md-4 me-lg-5" :class="this.$route.name == 'billing' ? 'active' : ''" role="button" @click="goTo('billing')">FACTURACIÓN</div>
            <div class="col-auto menuLink me-3 me-md-4 me-lg-5" :class="this.$route.name == 'career' ? 'active' : ''" role="button" @click="goTo('career')">HAZ CARRERA</div> 
            <div class="col-auto menuLink me-0 me-md-2" :class="this.$route.name == 'login' || this.$route.name == 'current' ? 'active' : ''" role="button" @click="goTo('login')">BPTEAM</div>-->
        </div>
    </div>
</template>
<script>
import menuContent from "@/components/common/headMenuContent.vue";
export default {
    name:"topMenu",
    emits:['event'],
    props:{
        user:{
            default:{
                name:'',
                lastname:'',
            },
            type: Object
        }
    },
    components:{
        menuContent
    },
    data(){
        return {
            menuShowing:false,
            a6:require('@/assets/images/a6.png'),
            a12:require('@/assets/images/a12.png'),
        }
    },
    methods:{
        toggleMenu(status){
            this.menuShowing = status;
        },
        goToHome(){
            this.$router.replace({ name: 'home' })
        },
        goTo(view){
            if(this.$route.name != view){
                this.$router.replace({ name: view })
            }
        },
    }
}
</script>
<style lang="css">
    #topMenu{
        height: 100px;
    }

    #topMenu.hide{
        animation: hide 0.2s ease 1 forwards;
    }

    #topMenu.show{
        animation: show 0.2s ease 1 forwards;
    }

    .menuLink:hover{
        color:var(--primary)
    }

    .menuLink.active{
        color:var(--primary)
    }

    @keyframes show {
        100%{
            opacity: 1;
        }
    }
    @keyframes hide {
        100%{
            opacity: 0;
        }
    }
</style>